<template>
  <div>
    <div class="modal_header">
      <el-row :gutter="10">
        <el-col :xs="24" :sm="24">
          <div class="grid-content bg-purple">
            <div class="title_h">{{ $t("message.add_movement") }}</div>
          </div>
        </el-col>
        <el-col :xs="24" :sm="24">
          <div class="grid-content bg-purple-light">
            <el-row class="button_r">
              <el-button
                type="success"
                size="small"
                class="mr-1"
                :loading="loadingButton"
                :disabled="loadingButton ? true : false"
                @click="submit(true)"
                >{{ $t("message.save") }}</el-button
              >
              <el-button
                type="warning"
                icon="el-icon-close"
                size="small"
                @click="resetForm('form')"
                >{{ $t("message.close") }}</el-button
              >
            </el-row>
          </div>
        </el-col>
      </el-row>
    </div>

    <div class="center__block_form">
      <el-form
        ref="form"
        :model="form"
        :rules="rules"
        class="aticler_m"
        size="small"
      >
        <el-row :gutter="25">
          <el-col :sm="12">
            <el-form-item
              :label="$t('message.main_warehouses')"
              prop="mainwarehouse_id"
            >
              <mainwarehouse
                v-on:clear-x="afterClosed()"
                v-model="form.mainwarehouse_id"
                :mainwarehouse_id="form.mainwarehouse_id"
                size="mini"
              ></mainwarehouse>
            </el-form-item>
          </el-col>
          <!-- end col -->
          <el-col :sm="12">
            <el-form-item
              v-if="form.mainwarehouse_id"
              :label="$t('message.warehouse_movement')"
              prop="warehouse_id"
            >
              <warehouse
                v-model="form.warehouse_id"
                :mainwarehouse_id="form.mainwarehouse_id"
                size="mini"
              ></warehouse>
            </el-form-item>
            <el-form-item
              v-else
              :label="$t('message.warehouse_movement')"
              prop="warehouse_id"
            >
              <el-select
                size="mini"
                v-model="value"
                disabled
                :placeholder="$t('message.warehouse_movement')"
              >
                <el-option
                  v-for="item in options"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <!-- end col -->
        </el-row>
        <!-- end el-row -->

        <el-card class="box-card mb-4">
          <el-col :span="18" class="mb-3">
            <el-input
              v-model="qrCode"
              ref="qrInput"
              @input="getProductByQr"
            ></el-input>
          </el-col>
          <div>
            <el-table border :data="items" :row-class-name="tableRowClassName">
              <el-table-column label="#" width="30">
                <template slot-scope="scope">{{ scope.$index + 1 }}</template>
              </el-table-column>
              <el-table-column :label="$t('message.name')">
                <template slot-scope="scope">{{
                  scope.row.product.name
                }}</template>
              </el-table-column>
              <el-table-column :label="$t('message.box_available')">
                <template slot-scope="scope">{{
                  scope.row.box_available
                }}</template>
              </el-table-column>
              <el-table-column :label="$t('message.available')">
                <template slot-scope="scope">{{
                  scope.row.available
                }}</template>
              </el-table-column>
              <el-table-column :label="$t('message.quantity')">
                <template slot-scope="scope">
                  <el-input v-model="scope.row.quantity"></el-input>
                </template>
              </el-table-column>
              <el-table-column :label="$t('message.box_quantity')">
                <template slot-scope="scope">
                  <el-input
                    min="1"
                    type="number"
                    v-model="scope.row.box_quantity"
                    size="mini"
                  ></el-input>
                </template>
              </el-table-column>
              <el-table-column :label="$t('message.actions')">
                <template slot-scope="scope">
                  <el-button
                    type="danger"
                    icon="el-icon-delete"
                    circle
                    @click="removeWriteOffItem(scope.row)"
                  ></el-button>
                </template>
              </el-table-column>
            </el-table>
          </div>

          <el-row :gutter="25">
            <!-- <el-col :span="11" class="mt-3">
              <el-form-item
                v-if="form.mainwarehouse_id"
                :label="$t('message.products')"
              >
                <products
                  @append="append"
                  v-model="form.product_id"
                  :product_id="form.product_id"
                ></products>
              </el-form-item>
              <el-form-item v-else :label="$t('message.products')">
                <el-select
                  v-model="value"
                  disabled
                  :placeholder="$t('message.products')"
                >
                  <el-option
                    v-for="item in options"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col> -->
          </el-row>
        </el-card>
      </el-form>
    </div>
  </div>
</template>
<script>
import mainwarehouse from "@/components/selects/mainwarehouse";
import warehouse from "@/components/selects/warehouse";
import { mapGetters, mapActions } from "vuex";
import drawer from "@/utils/mixins/drawer";
import drawerChild from "@/utils/mixins/drawer-child";
import products from "@/components/selects/products";
import form from "@/utils/mixins/form";
export default {
  mixins: [form, drawer, drawerChild],
  components: {
    warehouse,
    mainwarehouse,
    products,
  },
  data() {
    return {
      qrCode: null,
      loadingButton: false,
      options: [],
      value: "",
      items: [],
    };
  },
  computed: {
    ...mapGetters({
      columns: "movings/columns",
      model: "movings/model",
      rules: "movings/rules",
    }),
  },
  methods: {
    ...mapActions({
      save: "movings/store",
      update: "movings/update",
      show: "movings/show",
      empty: "movings/empty",
      serachByCode: "products/serachByCode",
      showProductRemainder: "movings/showrem",
    }),
    beforeClose(done) {
      alert("asdasd");
      this.items = [];
      done();
    },
    afterClosed() {
      this.items = [];
    },
    resetForm(formName) {
      this.$refs[formName].resetFields();
      this.close();
    },
    getProductByQr: _.debounce(function (val) {
      if (val != "" && Number(val))
        this.serachByCode(val)
          .then((res) => {
            if (res.data.product != null) this.append(res.data.product);
            else
              this.$notify({
                title: this.$t("message.validation"),
                type: "error",
                position: "bottom right",
                message: "product not found",
              });
          })
          .finally((fin) => {
            this.qrCode = null;
          });
      else this.qrCode = null;
    }, 500),
    append(product) {
      const item = this.items.find((item) => item.product_id == product.id);
      this.loadingItems = true;
      const payload = {
        product_id: product.id,
        warehouse_id: this.form.mainwarehouse_id,
      };
      this.showProductRemainder(payload)
        .then((response) => {
          const product = response.data.product;
          if (!item) {
            this.items.push({
              product_id: product.id,
              available: product.available ? product.available : 0,
              box_available: product.available ? product.available : 0,
              quantity: 0,
              box_quantity: 0,
              product: {
                name: product.name,
              },
            });
          } else {
            item.quantity += 1;
          }
          this.loadingItems = false;
        })
        .catch((error) => {
          console.log(error.response);
        });
    },
    afterOpened() {
      this.form = JSON.parse(JSON.stringify(this.model));
    },
    removeWriteOffItem(item) {
      const index = this.items.indexOf(item);
      this.items.splice(index, 1);
    },
    submit(close = true) {
      this.$refs["form"].validate((valid) => {
        if (valid) {
          const payload = {
            mainwarehouse_id: this.form.mainwarehouse_id,
            warehouse_id: this.form.warehouse_id,
            items: this.items,
          };

          this.save(payload)
            .then((response) => {
              this.$alert(response);
              if (close == true) {
                this.close();
              }
            })
            .catch((error) => {
              this.$alert(error);
            });
        }
      });
    },
    tableRowClassName({ row, rowIndex }) {
      if (row.available == 0) {
        return "warning-row";
      }
    },
  },
};
</script>
<style>
.el-table tr.warning-row {
  background-color: #e6a23c;
}
</style>